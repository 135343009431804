import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';

const PageNotFound = ({ text }) => {
    return (
        <div className='errorPage flex-column'>
            <Player
                src={require("../../assets/images/no-inv-found.json")}
                className="w-100" loop autoplay />
            <p className='text-uppercase fw-600'>{text || ""}</p>
        </div>
    )
}
export default PageNotFound
