import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const sofSolution = [
  {
    img: "features_1.png",
    title: "Create Your Account",
    desc: "Create an account now to have access to our platform's complete collection of services and easily manage your company.",
  },
  {
    img: "features_2.png",
    title: "Generate & Send Invoices",
    desc: "Start by easily creating and sending bills, which will give your business efficient billing systems and safe payment choices.",
  },
  {
    img: "features_3.png",
    title: "Track Payments & Get Notified",
    desc: "Keep track of payments and get real-time alerts to make sure you stay on top of your finances and keep control of your business transactions.",
  },
];

const Works = () => {
  return (
    <section className="softwareSolutions">
      <img
        src={require("../../assets/images/backEllipse.png")}
        draggable={false}
        alt="ellipse"
        className="ellipse"
      />
      <Container>
        <Row>
          <Col lg={7} className="mx-auto text-center">
            <h5 className="pHeading-md fw-800">How It Works</h5>
            <p className="pPrimhText colPurp">
              Simplify billing in 3 steps: Create account, draft & send
              invoices, and track payments with real-time notifications.
            </p>
          </Col>
        </Row>
        <Row>
          {sofSolution.length > 0
            ? sofSolution?.map((solutionContent, index) => {
                const { img, title, desc } = solutionContent;
                return (
                  <Col md={4} sm={6} key={index}>
                    <div
                      className="solutionBox my-4 text-center solutionBoxWork"
                      data-aos-once={false}
                      data-aos-duration="2000"
                      data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
                    >
                      <img
                        src={require(`../../assets/images/${img}`)}
                        className="img-fluid"
                        draggable={false}
                        alt="service"
                      />
                      <h6 className="my-3 fw-700">{title || "--"}</h6>
                      <p className="pPrimhText colPurp">{desc || "--"}</p>
                    </div>
                  </Col>
                );
              })
            : ""}
        </Row>
      </Container>
    </section>
  );
};

export default Works;
