// ======== BASE URL ===================== //
const config = require("../helpers/config.json");
// ===================================|| Stripe Apis ||========================================// 
export const createStripeCustomerRoute = `${config["BASEAPIURL"]}/api/sales/createStripeCustomer`;
export const getAllStripeCustomerRoute = `${config["BASEAPIURL"]}/api/sales/getAllStripeCustomer`;
export const getStripeCustomerbyIdRoute = `${config["BASEAPIURL"]}/api/sales/getStripeCustomer`;
export const cardsStripeByCustomerRoute = `${config["BASEAPIURL"]}/api/sales/getAListOfCardsOnStripeByCustomer`;
export const cardOfCustomerOnStripeRoute = `${config["BASEAPIURL"]}/api/sales/getACardOfCustomerOnStripebyCardIdAndCusId`;
export const createCustomerCardOnStripeRoute = `${config["BASEAPIURL"]}/api/sales/createCustomerCardOnStripe`;
export const createAnInvoiceByCustomerIDRoute = `${config["BASEAPIURL"]}/api/sales/createAnInvoiceByCustomerID`;
// ===================================|| Brand- Apis ||========================================//
export const createBrandRoute = `${config["BASEAPIURL"]}/api/brand/registerBrand`;
export const getAllbrandsRoute = `${config["BASEAPIURL"]}/api/brand/getAllbrands`;
export const getBrandByIdRoute = `${config["BASEAPIURL"]}/api/brand/getBrandById`;
export const updateBrandRoute = `${config["BASEAPIURL"]}/api/brand/updateBrand`;
export const getAllBrandsNameAndLogoRoute = `${config["BASEAPIURL"]}/api/brand/getAllBrandsNameAndLogo`;
export const availableTimeZonesRoute = `${config["BASEAPIURL"]}/api/brand/getAllTimeZoneForBrands`;
// ===================================|| Unit - Apis ||========================================//  
export const getAllUnitsNameAndDescRoute = `${config["BASEAPIURL"]}/api/lead/getAllUnitsNameAndDesc`;
export const getAllUnitsRoute = `${config["BASEAPIURL"]}/api/unit/getAllUnits`;
export const createUnitRoute = `${config["BASEAPIURL"]}/api/unit/createUnit`;
export const getUnitByIdRoute = `${config["BASEAPIURL"]}/api/unit/getUnitById`;
export const updateActiveStatusByIdRoute = `${config["BASEAPIURL"]}/api/unit/updateActiveStatusById`;
export const checkSalesPersonInUnitIdRoute = `${config["BASEAPIURL"]}/api/unit/checkSalesPersonInUnit`;
export const assignUnitHeadToUnitRoute = `${config["BASEAPIURL"]}/api/unit/assignUnitHeadToUnit`;
export const assignUnitHeadToUnitFromOtherUnitRoute = `${config["BASEAPIURL"]}/api/unit/assignUnitHeadToUnitFromOtherUnit`;
export const getBrandListThatAreNotAssignedToUnitsRoute = `${config["BASEAPIURL"]}/api/unit/getBrandListThatAreNotAssignedToUnits`;
export const assignBrandToUnitRoute = `${config["BASEAPIURL"]}/api/unit/assignBrandToUnit`;
export const getAllSaleEmployeesOfThisUnitRoute = `${config["BASEAPIURL"]}/api/unit/getAllSaleEmployeesOfThisUnit`;
export const getSalesEmpNotAssignedToUnitsRoute = `${config["BASEAPIURL"]}/api/unit/getSalesEmployeesThatAreNotAssignedToAnyUnits`;
export const addSalesEmployeeToUnitRoute = `${config["BASEAPIURL"]}/api/unit/addSalesEmployeeToUnit`;
export const getUnitHeadsOfUnitRoute = `${config["BASEAPIURL"]}/api/unit/getUnitHeadsOfUnit`;
export const removeUnitHeadFromUnitRoute = `${config["BASEAPIURL"]}/api/unit/removeUnitHeadFromUnit`;
export const removeUnitHeadFromUnitAndUpdateDesignationRoute = `${config["BASEAPIURL"]}/api/unit/removeUnitHeadFromUnitAndUpdateDesignation`;
export const getMyUnitsOfUnitHeadRoute = `${config["BASEAPIURL"]}/api/unit/getMyUnitsOfUnitHead`;
// ===================================|| Clients- Apis ||========================================// 
export const createClientRoute = `${config["BASEAPIURL"]}/api/client/createClient`;
export const getEmployeeUnitsRoute = `${config["BASEAPIURL"]}/api/client/getEmployeeUnits`;
export const getMyClientsBySalesPersonRoute = `${config["BASEAPIURL"]}/api/client/getMyClientsBySalesPerson`;
export const getAllClientsByUnitRoute = `${config["BASEAPIURL"]}/api/client/getAllClientsByUnit`;
export const updateClientByIdRoute = `${config["BASEAPIURL"]}/api/client/updateClientById`;
export const getAllClientsRoute = `${config["BASEAPIURL"]}/api/client/getAllClients`;
// ===================================|| Invoices- Apis ||========================================// 
export const getUnitBrandClientByIdRoute = `${config["BASEAPIURL"]}/api/invoice/GetUnitBrandClientBySalesPersonId`;
export const getBrandsClientsByUnitIdAndSalesPersonIdRoute = `${config["BASEAPIURL"]}/api/invoice/getBrandsClientsByUnitIdAndSalesPersonId`;
export const getAllinvoicesRoute = `${config["BASEAPIURL"]}/api/invoice/getAllinvoices`;
export const getInvoiceByIdForEditRoute = `${config["BASEAPIURL"]}/api/invoice/getInvoiceByIdForEdit`;
export const cancelAndReviseInvoiceByIdRoute = `${config["BASEAPIURL"]}/api/invoice/cancelAndReviseInvoiceById`;
// ===================================|| GateWay- Apis ||========================================//  
export const createGatewayRoute = `${config["BASEAPIURL"]}/api/gateway/createGateway`;
export const getStripeAccountsRoute = `${config["BASEAPIURL"]}/api/gateway/getAllStripeGatewayAccounts`;
export const getPaypalAccountsRoute = `${config["BASEAPIURL"]}/api/gateway/getAllPaypalGatewayAccounts`;
export const changeActiveStateByGatewayIdRoute = `${config["BASEAPIURL"]}/api/gateway/changeActiveStateByGatewayId`;
export const getGatewayAccountByIdRoute = `${config["BASEAPIURL"]}/api/gateway/getGatewayAccountById`;
export const updateGatewayByIdRoute = `${config["BASEAPIURL"]}/api/gateway/updateGatewayById`;
export const getActiveStripeGatewayAccountsRoute = `${config["BASEAPIURL"]}/api/gateway/getActiveStripeGatewayAccounts`;
export const getActivePaypalGatewayAccountsRoute = `${config["BASEAPIURL"]}/api/gateway/getActivePaypalGatewayAccounts`;
export const createInvoiceRoute = `${config["BASEAPIURL"]}/api/invoice/createInvoice`;
export const getMyInvoicesBySalesPersonRoute = `${config["BASEAPIURL"]}/api/invoice/getMyInvoicesBySalesPerson`;
export const getAllinvoicesByUnitRoute = `${config["BASEAPIURL"]}/api/invoice/getAllinvoicesByUnit`;
export const getInvoiceByIdRoute = `${config["BASEAPIURL"]}/api/invoice/getInvoiceById`;
export const getPubKeyBySelectedStripeGatewayRoute = `${config["BASEAPIURL"]}/api/gateway/getPubKeyBySelectedStripeGateway`;
export const createStripePaymentIntentRoute = `${config["BASEAPIURL"]}/api/gateway/createStripePaymentIntent`;
export const updatePaidStatusByInvoiceIdRoute = `${config["BASEAPIURL"]}/api/gateway/updatePaidStatusByInvoiceId`;
export const updateGatewayPaymentStatusInvByInvObjIdRoute = `${config["BASEAPIURL"]}/api/gateway/updateGatewayaymentStatusInvoiceByInvoiceObjectId`;
export const getClientIdBySelectedPaypalGatewayIdRoute = `${config["BASEAPIURL"]}/api/gateway/getClientIdBySelectedPaypalGateway`;
export const updatePaidStatusByPaypalPayByInvIdRoute = `${config["BASEAPIURL"]}/api/gateway/updatePaidStatusThroughPaypalPaymentByInvoiceId`;
export const createWireBankGatewayRoute = `${config["BASEAPIURL"]}/api/gateway/createWireBankGateway`;
export const getAllWireAccountsRoute = `${config["BASEAPIURL"]}/api/gateway/getAllWireAccounts`;
export const getActiveWireGatewayAccountsRoute = `${config["BASEAPIURL"]}/api/gateway/getActiveWireGatewayAccounts`;
export const getWireGatewayAccountByIdRoute = `${config["BASEAPIURL"]}/api/gateway/getWireGatewayAccountById`;
export const updateWireGatewayByIdRoute = `${config["BASEAPIURL"]}/api/gateway/updateWireGatewayById`;
export const getWireGatewayDetailsByIdAndCodeRoute = `${config["BASEAPIURL"]}/api/gateway/getWireGatewayDetailsByIdAndCode`;
export const updatePaidStatusThroughWirePaymentByInvoiceIdRoute = `${config["BASEAPIURL"]}/api/gateway/updatePaidStatusThroughWirePaymentByInvoiceId`;
export const getAllAuthorizenetGatewayAccountsRoute = `${config["BASEAPIURL"]}/api/gateway/getAllAuthorizenetGatewayAccounts`;
export const getActiveAuthorizenetGatewayAccountsRoute = `${config["BASEAPIURL"]}/api/gateway/getActiveAuthorizenetGatewayAccounts`;
export const createAuthorizenetPaymentRoute = `${config["BASEAPIURL"]}/api/gateway/createAuthorizenetPayment`;
export const getStatementDescriptorByGatewayIdRoute = `${config["BASEAPIURL"]}/api/gateway/getStatementDescriptorByGatewayId`;
// ===================================|| Tracking - Apis ||========================================//  
export const trackClientInvByIdRoute = `${config["BASEAPIURL"]}/api/tracking`;
export const getGeoLocationRoute = `https://geolocation-db.com/json/`;
export const getAllCountries = `https://countriesnow.space/api/v0.1/countries/iso`;
export const getCountriesState = `https://countriesnow.space/api/v0.1/countries/states`;
export const getCountriesCity = `https://countriesnow.space/api/v0.1/countries/state/cities`;
// ============================== //
export const getAllClientsInvoicesLinkTrackingRoute = `${config["BASEAPIURL"]}/api/tracking/getAllClientsInvoicesLinkTracking`;


