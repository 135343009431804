import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import Title from "../../../hook/Title";
import ReactToPrint from "react-to-print";
import {
  getClientIdBySelectedPaypalGatewayIdRoute,
  updatePaidStatusByPaypalPayByInvIdRoute,
  getWireGatewayDetailsByIdAndCodeRoute,
  getPubKeyBySelectedStripeGatewayRoute,
  createStripePaymentIntentRoute,
  trackClientInvByIdRoute,
  getGeoLocationRoute,
  getInvoiceByIdRoute,
} from "../../../utils/APIRoutes";
import { useNavigate, useParams } from "react-router-dom";
import currencySymbols from "../../../helpers/currencySymbols.json";
import CustomModal from "../../../Components/CustomModal";
import { FaTimes, FaPrint, FaDownload } from "react-icons/fa";
// stripe  payment !
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { loadScript } from "@paypal/paypal-js";
import { toast } from "react-toastify";
import axios from "axios";
import StripeCheckOutForm from "../../../Components/ScreenComponents/StripeCheckOutForm";
import AuthorizeMerchant from "../../../Components/ScreenComponents/AuthorizeMerchant";
import "../../../assets/css/invoice.css";
import ErrorPage from "../../../Components/ScreenComponents/ErrorPage";
import PageNotFound from "../../../Components/ScreenComponents/PageNotFound";

const Invoice = () => {
  Title("My Invoice");

  let toastOptions = {
    position: "top-left",
    autoClose: 4000,
    pauseOnHover: false,
    draggable: false,
    theme: "light",
  };
  let currencyCodeSymbols = currencySymbols.codes;
  const navigate = useNavigate();
  const { _id } = useParams();
  let componentRef = useRef();
  const [servError, setServError] = useState(false);
  const [invStatus, setInvStatus] = useState("");
  const [trackingDetails, setTrackingDetails] = useState({});
  const [notFound, setNotFound] = useState(false);
  const [wireGatewayFound, setWireGatewayFound] = useState(null);

  ////////////////////////////// Tracking Data of client Inv link //////////////////////////
  const trackUser = async () => {
    const trackedResponse = await fetch(getGeoLocationRoute);
    const trackedResult = await trackedResponse.json();
    if (trackedResult) {
      setTrackingDetails(trackedResult);
      const trackedResponse = await axios.post(
        `${trackClientInvByIdRoute}/createClientInvoiceLinkTrack?invoiceObjectId=${_id}`,
        trackedResult
      );
      // console.log("🚀 ~ file: Invoice.js:192 ~ Invoice ~ trackedResponse:", trackedResponse)
    } else {
      // console.log("not Found")
    }
  };

  ////////////////////////////// Storing Data of Brand //////////////////////////
  const [brand, setBrand] = useState([]);
  const [client, setClient] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [salePerson, setSalePerson] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [paymentGatewayAvailable, setPaymentGatewayAvailable] = useState([]);
  const [paidByGateway, setPaidByGateway] = useState([]);
  const [invDueDate, setInvDueDate] = useState("");
  const { name, email, organization, phone } = client;
  const { brandEmail, brandName, brandPhone, brandWebsite, brandLogoUrl } =
    brand;
  const { invoiceId, dueDate, createdAt, currency, paid } = invoice;
  let InvDueDate = new Date(parseInt(dueDate));
  InvDueDate = InvDueDate.toString();

  /////////////////////////////  Fetch Invoice Api  /////////////////////////////
  const getInvoiceDetails = async () => {
    try {
      const response = await axios.get(
        `${getInvoiceByIdRoute}?invoiceId=${_id}`
      );
      if (response?.data?.success === true) {
        setInvDueDate(response?.data?.invDueDate);
        setBrand(response?.data?.brand);
        setClient(response?.data?.client);
        setInvoice(response?.data?.invoice);
        setSalePerson(response?.data?.salePerson);
        setLineItems(response?.data?.invoice.lineItems);
        setPaymentGatewayAvailable(
          response?.data?.invoice.paymentGatewayAvailable
        );
        setPaidByGateway(response?.data?.invoice.paidByGateway);
        setInvStatus(response?.data?.invoice.status);
        setWireGatewayFound(response?.data?.wireGateway);
        setServError(false);
      } else {
        if (response?.status === 200) {
          setNotFound(true);
          throw new Error(`${response?.status} & ${response?.data?.error}`);
        } else {
          setServError(true);
          throw new Error(`${response?.status} & ${response?.data?.error}`);
        }
      }
    } catch (error) {
      console.log("*****|| Error Details ||*****", error);
      if (error.response.status === 500) {
        setServError(true);
      }
    }
  };

  useEffect(() => {
    getInvoiceDetails();
    trackUser();
  }, []);

  ///////////////////////////////// Getting Payment merchant and proceeding to payment ! ////////////////////////////////////
  const [merchVal, setMerchVal] = useState("");
  const [stripeModal, setStripeModal] = useState(false);
  const [paypalModal, setPaypalModal] = useState(false);
  const [wireModal, setWireModal] = useState(false);
  const [wireGateId, setWireGateId] = useState("");
  const [authorizeGateId, setAuthorizeGateId] = useState("");
  //==========================================================//
  const [stripePromise, setStripePromise] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [encryptionCode, setEncryptionCode] = useState("");
  const [wireData, setWireData] = useState("");
  const [detailsShow, setDetailsShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const paypalRef = useRef();

  const handlePaymentForm = async (e) => {
    e.preventDefault();
    if (merchVal === "" || merchVal === undefined || merchVal === null) {
      toast.error(
        "Please select payment method first !",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
    } else {
      let gatewayData = JSON.parse(merchVal);
      let gatewayname = JSON.parse(merchVal).gatewayName;
      if (gatewayname === "stripe") {
        setStripeModal(true);
        try {
          const result1 = await fetch(
            `${getPubKeyBySelectedStripeGatewayRoute}?gatewayId=${gatewayData._id}`
          );
          const response1 = await result1.json();
          if (response1.success === true) {
            setStripePromise(loadStripe(response1.publishableKey));
            const result2 = await axios.post(
              `${createStripePaymentIntentRoute}?gatewayId=${gatewayData?._id}&currency=${currency}&amount=${invoice?.totalAmount}&invoiceObjectId=${invoice?._id}`
            );
            if (result2.data.success === true) {
              setClientSecret(result2.data.clientSecret);
              setServError(false);
            } else {
              throw new Error("Something went wrong.");
            }
            setServError(false);
          } else {
            throw new Error("Something went wrong.");
          }
        } catch (error) {
          setServError(true);
        }
      }
      if (gatewayname === "paypal") {
        setPaypalModal(true);
        let paypal;
        try {
          const clientData = await axios.get(
            `${getClientIdBySelectedPaypalGatewayIdRoute}?gatewayId=${gatewayData?._id}`
          );
          const clientId = clientData?.data.clientId;
          if (clientData.data.success === true) {
            paypal = await loadScript({
              "client-id": clientId,
              currency: currency.toUpperCase(),
            });
          } else {
            toast.error(
              "Please Select Another Payment Method.",
              toastOptions,
              (toastOptions["position"] = "top-right")
            );
            setPaypalModal(false);
          }
        } catch (error) {
          console.error("failed to load the PayPal JS SDK script", error);
        }
        if (paypal) {
          try {
            paypal
              .Buttons({
                createOrder: (data, actions, err) => {
                  const payableAmount = invoice?.totalAmount;
                  return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: [
                      {
                        amount: {
                          currency_code: currency.toUpperCase(),
                          value: parseInt(payableAmount),
                        },
                      },
                    ],
                  });
                },
                onApprove: async (data, actions) => {
                  const order = await actions.order.capture();
                  if (order) {
                    // if payment was successfulll then run custom api to change invoice payment status in crm
                    const response = await axios.put(
                      `${updatePaidStatusByPaypalPayByInvIdRoute}?gatewayId=${gatewayData?._id}&invoiceObjectId=${_id}`,
                      {
                        orderId:
                          order.purchase_units[0].payments.captures[0].id ===
                            "" ||
                          order.purchase_units[0].payments.captures[0].id ===
                            undefined ||
                          order.purchase_units[0].payments.captures[0].id ===
                            null
                            ? ""
                            : order.purchase_units[0]?.payments.captures[0]?.id,
                        status: "succeeded",
                        createTime: order.create_time,
                        trackingDetails,
                      }
                    );
                    if (response) {
                      navigate(
                        `/payment-completion/${_id}/${gatewayData?._id}`
                      );
                      setPaypalModal(false);
                    }
                  }
                },
                onError: (err) => {},
              })
              .render(paypalRef.current);
          } catch (error) {
            console.error("failed to render the PayPal Buttons", error);
          }
        }
      }
      if (gatewayname === "wire") {
        setWireModal(true);
        setWireGateId(gatewayData?._id);
      }
      if (gatewayname === "authorizenet") {
        setAuthorizeModal(true);
        setAuthorizeGateId(gatewayData?._id);
      }
    }
  };
  const verifyEncryptionCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (
        encryptionCode === "" ||
        encryptionCode === undefined ||
        encryptionCode === null
      ) {
        toast.error(
          "Decryption Code Is Required ! ",
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
      } else {
        const bankDetails = await axios.get(
          `${getWireGatewayDetailsByIdAndCodeRoute}?gatewayId=${wireGateId}&encryptionCode=${encryptionCode}`
        );
        if (bankDetails.data.success === true) {
          setWireData(bankDetails.data.gateway);
          setIsLoading(false);
          setDetailsShow(false);
          setEncryptionCode("");
        } else {
          setWireData("");
          setIsLoading(false);
          toast.error(
            bankDetails.data.error,
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
        }
      }
    } catch (error) {
      console.error("error in bank details fetching !", error);
      setIsLoading(false);
    }
  };
  const closeBankModal = () => {
    setDetailsShow(true);
    setWireModal(false);
    setEncryptionCode("");
  };
  // Authorize Modal Code !..
  const [authorizeModal, setAuthorizeModal] = useState(false);

  return (
    <>
      {notFound === true ? (
        <PageNotFound text="Invoice Not Found" />
      ) : servError === true ? (
        <ErrorPage />
      ) : (
        <>
          <div className="overflow-lg-hidden">
            <Row>
              <Col xxl={5} xl={8} className="mx-auto my-3 mt-5">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  {paid === true ? (
                    <>
                      {/* /////////////////////// Print An invoice  /////////////////////// */}
                      <div className="d-flex my-2 mx-2">
                        <ReactToPrint
                          trigger={() => (
                            <button className="sm-btn main-btn rounded-1 me-3">
                              Download
                            </button>
                          )}
                          content={() => componentRef}
                        />
                        <ReactToPrint
                          trigger={() => (
                            <button className="sm-btn main-btn rounded-1 me-3">
                              Print
                            </button>
                          )}
                          content={() => componentRef}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {/* /////////////////////// Print An invoice  /////////////////////// */}
                      <div className="d-flex my-2 mx-2">
                        <ReactToPrint
                          trigger={() => (
                            <button className="sm-btn main-btn rounded-1 me-3">
                              {" "}
                              <FaDownload /> Download
                            </button>
                          )}
                          content={() => componentRef}
                        />
                        <ReactToPrint
                          trigger={() => (
                            <button className="sm-btn main-btn rounded-1 me-3">
                              {" "}
                              <FaPrint /> Print
                            </button>
                          )}
                          content={() => componentRef}
                        />
                      </div>
                      {invStatus === "cancelled" || invStatus === "refunded" ? (
                        ""
                      ) : (
                        <>
                          <form
                            onSubmit={handlePaymentForm}
                            className="d-flex align-items-center gap-2 mx-2"
                          >
                            <label
                              htmlFor="paymentMethod"
                              className="flex-shrink-0"
                            >
                              Pay with :
                            </label>
                            <select
                              name="paymentMethod"
                              onChange={(e) => setMerchVal(e.target.value)}
                              id="paymentMethod"
                              className="primary-inputs px-2 pe-4 fs-13 fw-700"
                            >
                              <option value="">Select Payment Method</option>
                              {paymentGatewayAvailable?.length > 0
                                ? paymentGatewayAvailable?.map(
                                    (gateWayData) => {
                                      if (
                                        gateWayData === null ||
                                        gateWayData === undefined ||
                                        JSON.stringify(gateWayData) === "{}"
                                      ) {
                                        {
                                          /* console.log("🚀 ~ file: Invoice.js:243 ~ paymentGatewayAvailable?.map ~ gateWayData:", gateWayData) */
                                        }
                                      } else {
                                        const { _id, gatewayName } =
                                          gateWayData;
                                        {
                                          /* console.log("🚀 ~ file: Invoice.js:247 ~ paymentGatewayAvailable?.map ~ gateWayData:", gateWayData) */
                                        }
                                        return (
                                          <option
                                            value={JSON.stringify(gateWayData)}
                                            key={_id}
                                          >
                                            {gatewayName === "stripe"
                                              ? "Credit/Debit Card"
                                              : gatewayName === "paypal"
                                              ? "PayPal"
                                              : gatewayName === "wire"
                                              ? "Wire/Bank Transfer"
                                              : gatewayName === "authorizenet"
                                              ? "Authorize.Net"
                                              : gatewayName}
                                          </option>
                                        );
                                      }
                                    }
                                  )
                                : ""}
                            </select>
                            <button
                              type="submit"
                              className="sm-btn sec-btn rounded-1"
                            >
                              Pay
                            </button>
                          </form>
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <div
              className="ember-view a4page"
              ref={(el) => (componentRef = el)}
            >
              <div className="pcs-template">
                <div className="pcs-template-header pcs-header-content">
                  <div className="pcs-template-fill-emptydiv" />
                </div>
                <div className="pcs-template-body">
                  <table style={{ width: "100%", tableLayout: "fixed" }}>
                    <tbody>
                      <tr>
                        <td>
                          <div className="uImg mb-2">
                            {brandLogoUrl === ("" || null || undefined) ? (
                              ""
                            ) : (
                              <img
                                draggable={false}
                                src={brandLogoUrl}
                                alt="brand logo"
                                className="img-fluid"
                              />
                            )}
                          </div>
                          <p className="fw-bold my-0">From,</p>
                          <p className="fw-bold my-0">{brandName || ""}</p>
                          <p className="my-0">{brandEmail || ""}</p>
                          {brandWebsite !== "" ? (
                            <a href={brandWebsite} className="my-0">
                              {brandWebsite || ""}
                            </a>
                          ) : (
                            ""
                          )}
                          <p className="my-0">{brandPhone || ""}</p>
                        </td>
                        <td className="text-align-right v-top">
                          <span className="pcs-entity-title fw-600">
                            INVOICE
                          </span>
                          <br />
                          <span
                            style={{ fontSize: "10pt" }}
                            className="pcs-label"
                          >
                            <b>{invoiceId || ""}</b>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    style={{
                      clear: "both",
                      width: "100%",
                      marginTop: "30px",
                      tableLayout: "fixed",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <div className="billto-section">
                            <label
                              style={{ fontSize: "10pt" }}
                              className="pcs-label"
                            >
                              Bill To,
                            </label>
                            <br />
                            <span className="d-block">
                              <strong>
                                <span className="pcs-customer-name">
                                  {name || ""}
                                </span>
                              </strong>
                            </span>
                            <span className="d-block">
                              <strong>
                                <span className="pcs-customer-name">
                                  {email || ""}
                                </span>
                              </strong>
                            </span>
                            <span className="d-block">
                              <strong>
                                <span className="pcs-customer-name">
                                  {phone || ""}
                                </span>
                              </strong>
                            </span>
                            <span className="d-block">
                              <strong>
                                <span className="pcs-customer-name">
                                  {organization || ""}
                                </span>
                              </strong>
                            </span>
                          </div>
                        </td>
                        <td style={{ verticalAlign: "bottom", width: "40%" }}>
                          <table
                            style={{
                              float: "right",
                              width: "100%",
                              tableLayout: "fixed",
                              wordWrap: "break-word",
                            }}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              {salePerson?.pseudoName === "" ||
                              salePerson?.pseudoName === undefined ||
                              salePerson?.pseudoName === null ? (
                                ""
                              ) : (
                                <tr>
                                  <td
                                    style={{
                                      padding: "5px 10px 5px 0px",
                                      fontSize: "10pt",
                                    }}
                                    className="text-align-right"
                                  >
                                    <span className="pcs-label">
                                      <b>Sales Agent :</b>
                                    </span>
                                  </td>
                                  <td className="text-align-right">
                                    <span>{salePerson?.pseudoName || ""}</span>
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td
                                  style={{
                                    padding: "5px 10px 5px 0px",
                                    fontSize: "10pt",
                                  }}
                                  className="text-align-right"
                                >
                                  <span className="pcs-label">
                                    <b>Invoice Date :</b>
                                  </span>
                                </td>
                                <td className="text-align-right">
                                  <span>{`${createdAt?.slice(
                                    5,
                                    7
                                  )}-${createdAt?.slice(
                                    8,
                                    10
                                  )}-${createdAt?.slice(0, 4)}`}</span>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "5px 10px 5px 0px",
                                    fontSize: "10pt",
                                  }}
                                  className="text-align-right"
                                >
                                  <span className="pcs-label">
                                    <b>Due Date :</b>
                                  </span>
                                </td>
                                <td className="text-align-right">
                                  <span>{invDueDate || ""}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    style={{ clear: "both", marginTop: "10px", width: "100%" }}
                  >
                    <label style={{ fontSize: "10pt" }} className="pcs-label">
                      Memo
                    </label>
                    <p
                      style={{
                        marginTop: "7px",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                      }}
                      className="pcs-notes"
                    >
                      {invoice?.memo === "" || invoice?.memo === undefined
                        ? "Let us know if you have any questions or concerns regarding this invoice, we are happy to assist you."
                        : JSON.stringify(invoice) !== "{}"
                        ? invoice?.memo
                        : "Please let us know if you have any questions or concerns regarding this invoice, we are happy to assist you."}
                    </p>
                  </div>
                  <div className="main-table-body">
                    <table
                      style={{
                        width: "100%",
                        marginTop: "20px",
                        tableLayout: "fixed",
                      }}
                      className="pcs-itemtable"
                      cellSpacing={0}
                      cellPadding={0}
                    >
                      <thead>
                        <tr style={{ height: "32px" }}>
                          <td
                            style={{
                              padding: "5px 0px 5px 5px",
                              width: "5%",
                              textAlign: "center",
                            }}
                            className="pcs-itemtable-header pcs-itemtable-breakword"
                          >
                            #
                          </td>
                          <td
                            style={{
                              padding: "5px 10px 5px 20px",
                              textAlign: "left",
                            }}
                            className="pcs-itemtable-header pcs-itemtable-breakword"
                          >
                            Item &amp; Description
                          </td>
                          <td
                            style={{
                              padding: "5px 10px 5px 5px",
                              width: "11%",
                              textAlign: "right",
                            }}
                            className="pcs-itemtable-header pcs-itemtable-breakword"
                          >
                            Qty
                          </td>
                          <td
                            style={{
                              padding: "5px 10px 5px 5px",
                              width: "11%",
                              textAlign: "right",
                            }}
                            className="pcs-itemtable-header pcs-itemtable-breakword"
                          >
                            Rate
                          </td>
                          <td
                            style={{
                              padding: "5px 10px 5px 5px",
                              width: "15%",
                              textAlign: "right",
                            }}
                            className="pcs-itemtable-header pcs-itemtable-breakword"
                          >
                            Amount
                          </td>
                        </tr>
                      </thead>
                      <tbody className="itemBody">
                        {lineItems.length > 0 ? (
                          lineItems?.map((item, index) => {
                            const { description, quantity, rate, total } = item;
                            return (
                              <tr
                                key={index}
                                className="breakrow-inside breakrow-after"
                              >
                                <td
                                  rowSpan={1}
                                  valign="top"
                                  style={{
                                    padding: "10px 0 10px 5px",
                                    textAlign: "center",
                                    wordWrap: "break-word",
                                  }}
                                  className="pcs-item-row"
                                >
                                  {++index}
                                </td>
                                <td
                                  rowSpan={1}
                                  valign="top"
                                  style={{ padding: "10px 0px 10px 20px" }}
                                  className="pcs-item-row"
                                >
                                  <p style={{ wordWrap: "break-word" }}>
                                    {description || ""}
                                  </p>
                                </td>
                                <td
                                  rowSpan={1}
                                  className="pcs-item-row lineitem-column text-align-right"
                                >
                                  <span>{quantity || ""}</span>
                                </td>
                                <td
                                  rowSpan={1}
                                  className="pcs-item-row lineitem-column text-align-right"
                                >
                                  <span>{rate || ""}</span>
                                </td>
                                <td
                                  rowSpan={1}
                                  className="pcs-item-row lineitem-column lineitem-content-right text-align-right"
                                >
                                  <span>{total || ""}</span>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="my-1">
                            <td colSpan={4}>No Item Found !</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div style={{ width: "100%", marginTop: "1px" }}>
                      <div className="v-top total-number-section">
                        <div style={{ whiteSpace: "pre-wrap" }} />
                      </div>
                      <div className="v-top total-section">
                        <table
                          className="pcs-totals"
                          cellSpacing={0}
                          width="100%"
                        >
                          <tbody>
                            <tr>
                              <td className="total-section-label text-align-right">
                                Sub Total
                              </td>
                              <td className="total-section-value text-align-right">
                                {JSON.stringify(invoice) !== "{}"
                                  ? Object.keys(currencyCodeSymbols).map(
                                      (objkey, index) => {
                                        if (currency === objkey) {
                                          return (
                                            <p className="mb-0" key={index}>
                                              {currencyCodeSymbols[objkey]}
                                              {parseInt(
                                                invoice?.subTotalAmount
                                              ).toLocaleString() || ""}
                                            </p>
                                          );
                                        }
                                      }
                                    )
                                  : ""}
                              </td>
                            </tr>
                            <tr
                              style={{ height: "40px" }}
                              className="pcs-balance"
                            >
                              <td className="total-section-label text-align-right">
                                <b>Total</b>
                              </td>
                              <td className="total-section-value text-align-right">
                                <b>
                                  {JSON.stringify(invoice) !== "{}"
                                    ? Object.keys(currencyCodeSymbols).map(
                                        (objkey, index) => {
                                          if (currency === objkey) {
                                            return (
                                              <p className="mb-0" key={index}>
                                                {currencyCodeSymbols[objkey]}{" "}
                                                {parseInt(
                                                  invoice?.subTotalAmount
                                                ).toLocaleString() || ""}
                                              </p>
                                            );
                                          }
                                        }
                                      )
                                    : ""}
                                </b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {paid === true ? (
                          <div className="text-end my-2">
                            <img
                              src={require("../../../assets/images/paidstamp.png")}
                              draggable={false}
                              alt="invoice paid"
                              className="paidStamp"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {invStatus === "cancelled" ? (
                          <div className="text-end my-2">
                            <img
                              src={require("../../../assets/images/cancelledStamp.png")}
                              draggable={false}
                              alt="invoice paid"
                              className="paidStamp"
                            />
                          </div>
                        ) : invStatus === "refunded" ||
                          invStatus === "partially_refunded" ? (
                          <div className="text-end my-2">
                            <img
                              src={require("../../../assets/images/refunded.png")}
                              draggable={false}
                              alt="invoice paid"
                              className="paidStamp"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div style={{ clear: "both" }} />
                    </div>
                    {wireGatewayFound !== null && paid === false ? (
                      <>
                        <div
                          style={{
                            clear: "both",
                            marginTop: "10px",
                            width: "100%",
                          }}
                        >
                          <label
                            style={{ fontSize: "10pt" }}
                            className="pcs-label text-decoration-underline"
                          >
                            Wire Bank Details
                          </label>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                            className="pcs-notes mb-0 my-1"
                          >
                            <b>Bank Name: </b> {wireGatewayFound?.bankName}
                          </p>
                          <p
                            style={{
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                            className="pcs-notes mb-0 my-1"
                          >
                            <b>Account Title: </b>{" "}
                            {wireGatewayFound?.accountName}
                          </p>
                          <p
                            style={{
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                            className="pcs-notes mb-0 my-1"
                          >
                            <b>Account Details: </b>{" "}
                            {wireGatewayFound?.description}
                          </p>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {paid === true ? (
                      paidByGateway[0]?.statusDescriptor === "" ||
                      paidByGateway[0]?.statusDescriptor === null ||
                      paidByGateway[0]?.statusDescriptor === undefined ? (
                        ""
                      ) : (
                        <div
                          style={{
                            clear: "both",
                            marginTop: "10px",
                            width: "100%",
                          }}
                        >
                          <label
                            style={{ fontSize: "10pt" }}
                            className="pcs-label"
                          >
                            Statement Descriptor
                          </label>
                          <br />
                          <p
                            style={{
                              marginTop: "7px",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                            className="pcs-notes"
                          >
                            {paidByGateway[0]?.statusDescriptor || ""}
                          </p>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <p className="footerText fs-13 lh">
                    {invoice?.footer === "" || invoice?.footer === undefined
                      ? "Thank you for choosing our services, we appreciate your business."
                      : JSON.stringify(invoice) !== "{}"
                      ? invoice?.footer
                      : "Thank you for choosing our services, we appreciate your business."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/*=================  Payment stripe modal starts here ! =================*/}
      <CustomModal
        backdrop="static"
        keyboard="False"
        size="md"
        show={stripeModal}
        onHide={() => setStripeModal(false)}
      >
        <button
          onClick={() => setStripeModal(false)}
          className="modal-cancel modal-ex-btn"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          {/* modal text */}
          {clientSecret && stripePromise && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <StripeCheckOutForm invoiceObjectId={_id} />
            </Elements>
          )}
        </div>
      </CustomModal>
      {/*=================  Payment Paypal modal starts here ! =================*/}
      <CustomModal
        backdrop="static"
        keyboard="False"
        size="md"
        show={paypalModal}
        onHide={() => setPaypalModal(false)}
      >
        <button
          onClick={() => setPaypalModal(false)}
          className="modal-cancel modal-ex-btn"
        >
          <FaTimes />
        </button>
        <div className="modalContent" ref={paypalRef}></div>
      </CustomModal>
      {/*=================  Wire/Bank Modal starts here ! =================*/}
      <CustomModal
        backdrop="static"
        keyboard="False"
        size="md"
        show={wireModal}
        onHide={closeBankModal}
      >
        <button onClick={closeBankModal} className="modal-cancel modal-ex-btn">
          <FaTimes />
        </button>
        <div className="modalContent">
          <form onSubmit={verifyEncryptionCode}>
            {detailsShow === true ? (
              <div className="text-start">
                <h6 className="mb-2 text-center">
                  <b>Please Enter Encryption Code To See Bank Details</b>
                </h6>
                <label className="text-bold mb-1" htmlFor="encryptioncode">
                  Decryption Code
                </label>
                <input
                  value={encryptionCode}
                  onChange={(e) => setEncryptionCode(e.target.value)}
                  name="encryptioncode"
                  id="encryptioncode"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter Decryption Code"
                />
                <small className="colGreen">
                  kindly Contact With Your Sales Person For Decryption Code*{" "}
                </small>
                <div className="text-center">
                  <button type="submit" className="main-btn mt-3">
                    {isLoading ? "Verifying..." : "Verify"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="text-start">
                <h6 className="mb-2 text-center">
                  <b>Bank Details</b>
                </h6>
                <div className="my-3">
                  <label className="mb-0" htmlFor="accountName">
                    Account Title
                  </label>
                  <p className="mb-0">{wireData.accountName}</p>
                </div>
                <div className="my-3">
                  <label className="mb-0" htmlFor="bankName">
                    Bank Name
                  </label>
                  <p className="mb-0">{wireData.bankName}</p>
                </div>
                <div className="my-3">
                  <label className="mb-0" htmlFor="currencyCode">
                    Currency Code
                  </label>
                  <p className="mb-0">{wireData.currencyCode}</p>
                </div>
                <div className="my-3">
                  <label className="mb-0" htmlFor="statusDescriptor">
                    Status Descriptor
                  </label>
                  <p className="mb-0">{wireData.statusDescriptor}</p>
                </div>
                <div className="my-3">
                  <label className="mb-0" htmlFor="Description">
                    Account Details
                  </label>
                  <textarea
                    disabled={true}
                    className="primary-inputs px-2"
                    value={wireData.description}
                    rows="3"
                  ></textarea>
                </div>
              </div>
            )}
          </form>
        </div>
      </CustomModal>
      {/*=================  Authorize Modal starts here ! =================*/}
      <CustomModal
        backdrop="static"
        keyboard="False"
        size="lg"
        show={authorizeModal}
        onHide={() => setAuthorizeModal(false)}
      >
        <button
          onClick={() => setAuthorizeModal(false)}
          className="modal-cancel modal-ex-btn"
        >
          <FaTimes />
        </button>
        <div className="modalContent merchantModal">
          <AuthorizeMerchant
            subTotalAmount={invoice?.subTotalAmount}
            currency={invoice?.currency}
            invObjId={_id}
            trackingDetails={trackingDetails}
            authorizeGateId={authorizeGateId}
          />
        </div>
      </CustomModal>
    </>
  );
};
export default Invoice;
