import React from 'react'
import Banner from '../../Components/SectionComponents/Banner'
import Systems from '../../Components/SectionComponents/Systems'
import SoftwareSolutions from '../../Components/SectionComponents/SoftwareSolutions'
import CrmServices from '../../Components/SectionComponents/OurServices'
import Works from '../../Components/SectionComponents/Works'
import WhyChooseUs from '../../Components/SectionComponents/WhyChooseUs'
import GrowBusiness from '../../Components/SectionComponents/GrowBusiness'
import ClientTestimonials from '../../Components/SectionComponents/ClientTestimonials'
import ContactUs from '../../Components/SectionComponents/Contactus'
import Footer from '../../Components/SectionComponents/Footer'

const Index = () => {
    return (
        <>
            <Banner />
            <Systems />
            <SoftwareSolutions />
            <CrmServices />
            <Works />
            <WhyChooseUs />
            <GrowBusiness />
            <ClientTestimonials />
            <ContactUs />
            <Footer />
        </>
    )
}

export default Index
