import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const sofSolution = [
  {
    img: "serv1.png",
    title: "Discover our platform",
    desc: "Use our all-in-one platform to meet your needs and reach your full potential today!",
  },
  {
    img: "serv2.png",
    title: "Seek Professional Advice",
    desc: "Get help from a professional to deal with problems and make smart choices.",
  },
  {
    img: "serv3.png",
    title: "Easy Providers Booking",
    desc: "Secure your services without any issues. Make your process easier right now.",
  },
  {
    img: "serv4.png",
    title: "Accelerate Growth",
    desc: "Boost your business and see it grow by massive amounts every year. Join forces with us to get an edge.",
  },
];

const SoftwareSolutions = () => {
  return (
    <section className="softwareSolutions">
      <img
        src={require("../../assets/images/backEllipse.png")}
        draggable={false}
        alt="ellipse"
        className="ellipse"
      />
      <Container>
        <Row>
          <Col lg={7} className="mx-auto text-center">
            <h5 className="pHeading-md fw-800">
              Access Everything Necessary for Growth
            </h5>
            <p className="pPrimhText colPurp">
              Use our strong platform and expert advice to get ahead of the
              competition and experience rapid yearly growth.
            </p>
          </Col>
        </Row>
        <Row>
          {sofSolution.length > 0
            ? sofSolution?.map((solutionContent, index) => {
                const { img, title, desc } = solutionContent;
                return (
                  <Col xl={3} md={4} sm={6} key={index}>
                    <div className="solutionBox my-4 text-center">
                      <img
                        src={require(`../../assets/images/${img}`)}
                        className="zoomAnim"
                        draggable={false}
                        alt="service"
                      />
                      <h6 className="my-3 fw-700">{title || "--"}</h6>
                      <p className="pPrimhText colPurp">{desc || "--"}</p>
                    </div>
                  </Col>
                );
              })
            : ""}
        </Row>
      </Container>
    </section>
  );
};

export default SoftwareSolutions;
