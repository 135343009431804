import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const WhyChooseUs = () => {
  return (
    <section className="whyChooseUs">
      <Container>
        <Row>
          <Col lg={6}>
            <h5 className="pHeading-md fw-800">Why Choose Us</h5>
            <p className="pPrimhText colPurp">
              Choose us for secure payments, real-time tracking, and exceptional
              customer support. Experience hassle-free billing and efficient
              financial management.
            </p>
            <Row>
              <Col lg={8}>
                <div
                  className="my-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-once={false}
                >
                  <h6 className="fw-800">1. Secure Payment Processing</h6>
                  <p>
                    Accept online payments simply by integrating secure payment
                    methods into your invoicing.
                  </p>
                </div>
                <div
                  className="my-4"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  data-aos-once={false}
                >
                  <h6 className="fw-800">2. Real-Time Payment Tracking</h6>
                  <p>
                    Monitor your invoice status and get updates as payments come
                    to ensure you are paid sooner.
                  </p>
                </div>
                <div
                  className="my-4"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  data-aos-once={false}
                >
                  <h6 className="fw-800">3. Exceptional Customer Support</h6>
                  <p>
                    Our dedicated support team is always available to assist you
                    with any questions or concerns.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={6} className="position-relative">
            <img
              src={require("../../assets/images/Macbook.png")}
              className="img-fluid macbook"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              alt="Macbook"
            />
            <div
              data-aos-duration="2000"
              data-aos-once={false}
              data-aos-easing="ease-out-cubic"
              data-aos="zoom-in-right"
              className="userReview review1"
            >
              <div className="useravatar">
                <img
                  src={require("../../assets/images/userAvatar.png")}
                  alt="user"
                />
              </div>
              <div>
                <h6 className="mb-0 fw-800">John</h6>
                {/* <p className="mb-0 pPrimhText">Designer of NASA</p> */}
              </div>
            </div>
            <div
              data-aos-duration="2000"
              data-aos-once={false}
              data-aos-easing="ease-out-cubic"
              data-aos="zoom-in-right"
              className="userReview review2"
            >
              <div className="useravatar">
                <img
                  src={require("../../assets/images/userAvatar2.png")}
                  alt="user"
                />
              </div>
              <div>
                <h6 className="mb-0 fw-800">Mike</h6>
                {/* <p className="mb-0 pPrimhText">Designer of NASA</p> */}
              </div>
            </div>
            <div
              data-aos-duration="2000"
              data-aos-once={false}
              data-aos-easing="ease-out-cubic"
              data-aos="zoom-in-left"
              className="userReview review3"
            >
              <div className="useravatar">
                <img
                  src={require("../../assets/images/userAvatar3.png")}
                  alt="user"
                />
              </div>
              <div>
                <h6 className="mb-0 fw-800">Steve</h6>
                {/* <p className="mb-0 pPrimhText">Designer of NASA</p> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhyChooseUs;
