import React from "react";
import { Modal } from "react-bootstrap";

const CustomModal = (props) => {
  return (
    <Modal
      {...props}
      size={props.size === "" ? "md" : props.size} 
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {props.children}
    </Modal>
  );
};

export default CustomModal;
