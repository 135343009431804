import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Footer = () => {
    const date = new Date();
    const year = date.getFullYear();
    return (
        <footer className='sitefooter'>
            <Container>
                <Row>
                    <Col lg={12} className='text-center'>
                        <p className='mb-0 my-2 text-capitalize'><small>© Copyright {year ?? "2024"}. Pay Ur Invoice. All right reserved</small></p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
