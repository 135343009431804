import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { updateGatewayPaymentStatusInvByInvObjIdRoute } from "../../utils/APIRoutes";
import axios from "axios";

export default function StripeCheckOutForm({ invoiceObjectId }) {
     const stripe = useStripe();
     const elements = useElements();
     const [message, setMessage] = useState(null);
     const [isProcessing, setIsProcessing] = useState(false);
     const handleSubmit = async (e) => {
          e.preventDefault();
          if (!stripe || !elements) {
               // Stripe.js has not yet loaded.
               // Make sure to disable form submission until Stripe.js has loaded.
               return;
          }
          setIsProcessing(true);
          const { error } = await stripe.confirmPayment({
               elements,
               confirmParams: {
                    // Make sure to change this to your payment completion page
                    // return_url: `http://localhost:3000/pms/card-payment-completion/${invoiceObjectId}`,
                    // For Server !!!!  
                    return_url: `https://payurinvoice.com/card-payment-completion/${invoiceObjectId}`,
               },
          });
          if (error.type === "card_error" || error.type === "validation_error") {
               setMessage(error.message);
               //  send error code to our api route so dev can trace the error code of client !
               const data = axios.put(`${updateGatewayPaymentStatusInvByInvObjIdRoute}?invoiceObjectId=${invoiceObjectId}&errorCode=${error.code}&errorMessage=${error.message}`);
               // console.log("~ file: payment ", data)
          } else {
               setMessage("An unexpected error occured.");
          }
          setIsProcessing(false);
     };

     return (
          <form id="payment-form" onSubmit={handleSubmit}>
               <PaymentElement id="payment-element" />
               <button className="main-btn mt-4" disabled={isProcessing || !stripe || !elements} id="submit">
                    <span id="button-text">{isProcessing ? "Processing ... " : "Pay now"}</span>
               </button>
               {/* Show any error or success messages */}
               {message && <div id="payment-message fw-600">{message}</div>}
          </form>
     );
}
