import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';

const ErrorPage = () => {
    return (
        <div className='errorPage'>
            <Player
                src={require("../../assets/images/page-not-found.json")}
                className="w-100" loop autoplay />
        </div>
    )
}

export default ErrorPage
