import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Systems = () => {
    return (
        <section className='systems my-4'>
            <Container>
                <Row className='g-0'>
                    <Col className='text-center'>
                        <img draggable={false} src={require("../../assets/images/shopify.png")} alt="shopify" className='img-fluid iconImages' />
                    </Col>
                    <Col className='text-center'>
                        <img draggable={false} src={require("../../assets/images/nearpod.png")} alt="nearpod" className='img-fluid iconImages' />
                    </Col>
                    <Col className='text-center'>
                        <img draggable={false} src={require("../../assets/images/bench.png")} alt="bench" className='img-fluid iconImages' />
                    </Col>
                    <Col className='text-center'>
                        <img draggable={false} src={require("../../assets/images/forbes.png")} alt="forbes" className='img-fluid iconImages' />
                    </Col>
                    <Col className='text-center'>
                        <img draggable={false} src={require("../../assets/images/bumble.png")} alt="bumble" className='img-fluid iconImages' />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Systems
