import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const GrowBusiness = () => {
  return (
    <section className="growBusiness">
      <Container>
        <Row className="align-items-center">
          <Col lg={6} className="px-5">
            <h5 className="pHeading-md fw-800">
              Use our all-inclusive acceleration method
            </h5>
            <p className="pPrimhText colPurp">
              Take your business to new heights with our comprehensive
              acceleration method. Here's how we can help:
            </p>
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={8}>
                <div
                  className="d-flex gap-3 stepsCol"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-once={false}
                >
                  <div className="sNo">
                    <span>1</span>
                  </div>
                  <div>
                    <h6 className="fw-800">Easy Steps</h6>
                    <p>
                      Follow our step-by-step guide to manage your finances
                      efficiently and stay in control of your business
                      transactions.
                    </p>
                  </div>
                </div>
                <div
                  className="d-flex gap-3 stepsCol"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-once={false}
                >
                  <div className="sNo">
                    <span>2</span>
                  </div>
                  <div>
                    <h6 className="fw-800">Proven Strategies</h6>
                    <p>
                      Benefit from proven strategies and techniques to optimize
                      your financial management and accelerate your business
                      growth.
                    </p>
                  </div>
                </div>
                <div
                  className="d-flex gap-3 stepsCol"
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-once={false}
                >
                  <div className="sNo">
                    <span>3</span>
                  </div>
                  <div>
                    <h6 className="fw-800">Success Stories</h6>
                    <p>
                      Hear from satisfied clients who have experienced the
                      benefits of our acceleration process firsthand.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GrowBusiness;
