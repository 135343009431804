import React, { useEffect, useState } from 'react';
import Title from '../../hook/Title';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Congratulate from '../Congratulate';
import { getStatementDescriptorByGatewayIdRoute } from '../../utils/APIRoutes';
import axios from 'axios';

const PaypalPayCompletion = () => {
     Title("Congratulations Payment Done 🎉");
     const { invoiceObjectId, gatewayData } = useParams();
     const [statementDescriptor, setStatementDescriptor] = useState("")
     const getStatusDesc = async () => {
          try {
               const { data } = await axios.get(`${getStatementDescriptorByGatewayIdRoute}?gatewayId=${gatewayData}`)
               setStatementDescriptor(data.statementDescriptor)
          } catch (err) {
               setStatementDescriptor("")
               console.log("🚀 ~ file: PaypalPayCompletion.js:18 ~ getStatusDesc ~ err:", err)
          }
     }

     useEffect(() => {
          getStatusDesc()
     }, [])

     return (
          <Row>
               <Col md={8} sm={10} className="mx-auto my-5 px-0">
                    <div className='box text-center rounded-1 mx-3 py-5'>
                         <Congratulate />
                         {statementDescriptor === null || statementDescriptor === undefined || statementDescriptor === "" ? "" : <h6 className='my-4'><b>Statement Descriptor:</b> {statementDescriptor}</h6>}
                         <Link className="main-btn" to={`/invoice/${invoiceObjectId}`}>View Paid Invoice</Link>
                    </div>
               </Col>
          </Row>
     );
}

export default PaypalPayCompletion;
