import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Contactus = () => {
  return (
    <section className="contactus">
      <Container>
        <Row>
          <Col lg={6}>
            <form>
              <Row>
                <Col lg={12} className="px-md-5">
                  <h5 className="pHeading-lg fw-800 mb-2 colWhite">
                    Have Any Question?
                  </h5>
                  <input
                    className="contactInp"
                    type="text"
                    placeholder="Name"
                    name="name"
                    id="name"
                  />
                  <input
                    className="contactInp"
                    type="email"
                    placeholder="Email"
                    name="email"
                    id="email"
                  />
                  <input
                    className="contactInp"
                    type="number"
                    placeholder="Phone"
                    name="phone"
                    id="phone"
                  />
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Message"
                    className="contactInp"
                    rows={5}
                  ></textarea>
                  <button className="pbtn my-4">Submit</button>
                </Col>
              </Row>
            </form>
          </Col>
          <Col lg={6}>
            <h5 className="pHeading-lg fw-800 mb-4 colWhite">Contact Us</h5>
            <p className="colWhite">
              Feel free to reach out to us with any inquiries or concerns. Our
              dedicated team is here to assist you. Contact us today via email,
              phone, or our online form.
            </p>
            <div className="d-flex gap-2 mb-2">
              <FaPhoneAlt color="#ff6057" />
              <h6 className="fw-800 colWhite">
                <a href="tel:+1-855-315-9950" className="colWhite">
                  +1 (855)-315-9950
                </a>
              </h6>
            </div>
            <div className="d-flex gap-2 mb-3">
              <FaEnvelope color="#ff6057" className="mt-1" />
              <a
                className="fw-800 colWhite d-block"
                href="mailto:info@payurinvoice.com"
              >
                info@payurinvoice.com
              </a>
            </div>
            <div className="d-flex gap-2 mb-2">
              <FaMapMarkerAlt color="#ff6057" />
              <h6 className="fw-800 colWhite">United States</h6>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contactus;
