import React, { useEffect } from "react";
import Routes from "./Routes/MainRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-loading-skeleton/dist/skeleton.css';
import "../src/assets/css/style.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      <Router basename="/">
        <Routes />
        <ToastContainer />
      </Router>
    </>
  );
}

export default App;
