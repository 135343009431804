import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Typewriter from "typewriter-effect";

const Banner = () => {
  return (
    <section className="banner">
      <div className="pageLogo">
        <img
          src={require("../../assets/images/logo.png")}
          draggable={false}
          alt="logo"
          className="img-fluid"
        />
      </div>
      <Container className="my-3">
        <Row className="align-items-center">
          <Col lg={6}>
            <div
              className="content"
              data-aos-once={false}
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-sine"
              data-aos-duration="2000"
            >
              <h6 className="fw-400 pHeading-lg">Pay ur Invoice</h6>
              <h1 className="pHeading-xl fw-800">
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("Simplify your billing process ")
                      .typeString("and get paid faster than ever before")
                      .start();
                  }}
                />
              </h1>
              <p className="pPrimhText">
                Our user-friendly software will simplify your billing process,
                remove administrative delays and increase your cash flow.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <img
              data-aos="fade-up"
              data-aos-duration="1000"
              src={require("../../assets/images/dashboard.png")}
              alt="dashboard"
              className="img-fluid"
              draggable={false}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
