import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Views/Home/Index";
import ErrorPage from "../Views/ErrorPage/Index";
import Invoice from "../Views/Company/Client/Invoice";
import StripePayCompletion from "../Components/ScreenComponents/StripePayCompletion";
import PaypalPayCompletion from "../Components/ScreenComponents/PaypalPayCompletion";

const MainRoutes = () => {
  const publicRoute = [
    {
      path: "/",
      element: <Home />
    },
    {
      path: "/invoice/:_id",
      element: <Invoice />,
    },
    {
      path: "/card-payment-completion/:invoiceObjectId",
      element: <StripePayCompletion />,
    },
    {
      path: "/payment-completion/:invoiceObjectId/:gatewayData",
      element: <PaypalPayCompletion />,
    },
    {
      path: "*",
      element: <ErrorPage />
    },
  ];
  return (
    <>
      <Routes>
        {/* ************************************|| PUBLIC ROUTES ||************************************ */}
        {publicRoute?.map((publicRoute, index) => {
          const { path, element } = publicRoute;
          return (
            <Route key={index} exact path={path} element={element} />
          )
        })}
      </Routes>
    </>
  );
};

export default MainRoutes;
