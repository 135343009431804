import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Title from '../../hook/Title';
import { useEffect } from 'react';
import axios from 'axios';
import { updatePaidStatusByInvoiceIdRoute, getGeoLocationRoute } from '../../utils/APIRoutes';
import { Link } from 'react-router-dom';
import Congratulate from '../Congratulate';

const StripePayCompletion = () => {

     Title("Congratulations Payment Done 🎉");
     const { invoiceObjectId } = useParams();
     const [statementDescriptor, setStatementDescriptor] = useState("")
     const getGeoLocation = async () => {
          const trackedResponse = await fetch(getGeoLocationRoute);
          const trackedResult = await trackedResponse.json();
          if (trackedResult) {
               const data = await axios.put(`${updatePaidStatusByInvoiceIdRoute}?invoiceObjectId=${invoiceObjectId}`, trackedResult);
               setStatementDescriptor(data?.data?.statementDescriptor)
          }
          else{
               const data = await axios.put(`${updatePaidStatusByInvoiceIdRoute}?invoiceObjectId=${invoiceObjectId}`, {
                    error: "No Tracking Found !"
               });
               setStatementDescriptor(data?.data?.statementDescriptor)
          }
     }

     useEffect(() => {
          getGeoLocation();
     }, []);

     return (
          <Row>
               <Col md={8} sm={10} className="mx-auto my-5 px-0">
                    <div className='box text-center rounded-1 mx-3 py-5'>
                         <Congratulate />
                         {statementDescriptor === null || statementDescriptor === undefined || statementDescriptor === "" ? "" : <h6 className='my-4'><b>Statement Descriptor:</b> {statementDescriptor}</h6>}
                         <Link className="main-btn" to={`/invoice/${invoiceObjectId}`}>View Paid Invoice</Link>
                    </div>
               </Col>
          </Row>
     );
}

export default StripePayCompletion;
