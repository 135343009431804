import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const sofSolution = [
  {
    img: "energy-saving.png",
    title: "User-Friendly Financial Software",
    desc: "Easy-to-use finance software for hassle-free money management",
  },
  {
    img: "analysis.png",
    title: "Automated Savings and Investment Features",
    desc: "Automatic saving and investment options for easy financial progress.",
  },
  {
    img: "centralized.png",
    title: "Convenient Bill Payment Processes",
    desc: "Simplify bill payments with our easy and user-friendly system.",
  },
  {
    img: "clock.png",
    title: "Automated Savings and Investment Features",
    desc: "Grow your money effortlessly with our automatic investing solutions.",
  },
];

const OurServices = () => {
  return (
    <section className="ourServices">
      <Container>
        <Row className="details">
          <Col lg={7} className="mx-auto text-center">
            <h5 className="pHeading-md fw-800">
              Keep Your Finances are well-organized and easily accessible
            </h5>
            <p className="pPrimhText colPurp">
              Our simple tools help you organize your finances and access them
              easily whenever you need.
            </p>
          </Col>
        </Row>
        <Row>
          {sofSolution.length > 0
            ? sofSolution?.map((solutionContent, index) => {
                const { img, title, desc } = solutionContent;
                return (
                  <Col
                    xl={3}
                    lg={4}
                    sm={6}
                    key={index}
                    className="mainCols my-2"
                  >
                    <div
                      className="solutionBox serviceSolutionBox"
                      data-aos={index % 2 == 0 ? "fade-up" : "fade-down"}
                      data-aos-once={false}
                      data-aos-duration="1000"
                    >
                      <img
                        src={require(`../../assets/images/${img}`)}
                        draggable={false}
                        alt="service"
                      />
                      <h6 className="my-3 fw-700">{title || "--"}</h6>
                      <p className="pPrimhText colPurp">{desc || "--"}</p>
                    </div>
                  </Col>
                );
              })
            : ""}
        </Row>
      </Container>
    </section>
  );
};

export default OurServices;
