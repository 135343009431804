import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <button type="button" className="pull-left">
        <FaArrowLeft />
      </button>
    </div>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <button type="button" className="pull-right">
        <FaArrowRight />
      </button>
    </div>
  );
};

const ClientTestimonials = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <section className="clientTestimonial">
      <img
        src={require("../../assets/images/halfLeftElipse.png")}
        draggable={false}
        alt="halfLeftElipse"
        className="elipseLeft"
      />
      <img
        src={require("../../assets/images/halfRightElipse.png")}
        draggable={false}
        alt="halfRightElipse"
        className="elipseRight"
      />
      <Container>
        <Slider {...settings}>
          <div>
            <Row className="align-items-center">
              <Col lg={6} className="px-5">
                <h5 className="pHeading-md fw-800 mb-4">
                  What Our Clients Say
                </h5>
                <div className="testUserWrap">
                  <img
                    src={require("../../assets/images/customer.png")}
                    alt="User"
                  />
                  <div className="my-2">
                    <h6 className="fw-800 mb-0">John</h6>
                    {/* <small>UX Designer</small> */}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="position-relative">
                  <img
                    src={require("../../assets/images/colon.png")}
                    draggable={false}
                    alt="colon"
                    className="colon"
                  />
                  <p className="pPrimText fw-700 mb-0 mt-4">
                    Guided by their professional advice, I make smart business
                    choices. Seamless experience all the way!
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row className="align-items-center">
              <Col lg={6} className="px-5">
                <h5 className="pHeading-md fw-800 mb-4">
                  What Our Clients Say
                </h5>
                <div className="testUserWrap">
                  <img
                    src={require("../../assets/images/customer-2.jpg")}
                    alt="User"
                  />
                  <div className="my-2">
                    <h6 className="fw-800 mb-0">Mike</h6>
                    {/* <small>UX Designer</small> */}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="position-relative">
                  <img
                    src={require("../../assets/images/colon.png")}
                    draggable={false}
                    alt="colon"
                    className="colon"
                  />
                  <p className="pPrimText fw-700 mb-0 mt-4">
                    Convenient bill payments, exceptional service. Highly
                    satisfied with their user-friendly system!
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row className="align-items-center">
              <Col lg={6} className="px-5">
                <h5 className="pHeading-md fw-800 mb-4">
                  What Our Clients Say
                </h5>
                <div className="testUserWrap">
                  <img
                    src={require("../../assets/images/customer-3.jpg")}
                    alt="User"
                  />
                  <div className="my-2">
                    <h6 className="fw-800 mb-0">Anna</h6>
                    {/* <small>UX Designer</small> */}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="position-relative">
                  <img
                    src={require("../../assets/images/colon.png")}
                    draggable={false}
                    alt="colon"
                    className="colon"
                  />
                  <p className="pPrimText fw-700 mb-0 mt-4">
                    Found my business potential and achieved goals effortlessly
                    with this all-in-one solution.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Slider>
      </Container>
    </section>
  );
};

export default ClientTestimonials;
