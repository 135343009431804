import React from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../hook/Title";
const Index = () => {
  Title("Page Not Found");
  const navigate = useNavigate();
  return (
    <div className="errorBox box m-md-5 m-2 p-md-5 p-2 text-center">
      <h4>Ooops...</h4>
      <h3>Error 404</h3>
      <p>We are sorry, but the page you are looking for does not exist.</p>
      <button className="main-btn my-2" onClick={() => navigate(-1)}>
        Go Back
      </button>
    </div>
  );
};

export default Index;
